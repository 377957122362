import React from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, SmallCloseIcon } from "@chakra-ui/icons";

function PriceInput({ price, setPrice, minmax }) {
  return (
    <InputGroup className="">
      <InputLeftElement
        pointerEvents="none"
        color="gray.400"
        fontSize="1em"
        children={minmax}
      />
      <Input
        size="md"
        type="number"
        className="text-center"
        border={"2px"}
        padding={"0"}
        placeholder={price}
        onChange={setPrice}
      />
      <InputRightElement
        pointerEvents="none"
        color="gray.300"
        fontSize="1.2em"
        children="€"
      />
    </InputGroup>
  );
}

function FilterSection({ filterItems, filter }) {
  return (
    <div className="py-4 font-sans hidden md:block">
      <div className="flex">
        <PriceMenu title={"Preis"} name="price" filter={filter} />
        <SimpleFilterMenu
          title={"Person"}
          name="person"
          items={filterItems}
          filter={filter}
        />
        <SimpleFilterMenu
          title={"Anlass"}
          name="event"
          items={filterItems}
          filter={filter}
        />
      </div>
      <div></div>
    </div>
  );
}

const PriceMenu = ({ title, name, filter }) => {
  const rangePrice = filter["rangeFilter"][name].values;
  const setFilterPrice = filter["rangeFilter"][name].set;
  const setMinPrice = (event) => {
    setFilterPrice([Math.max(event.target.value, 0), rangePrice[1]]);
  };
  const setMaxPrice = (event) => {
    setFilterPrice([rangePrice[0], Math.max(event.target.value, 0)]);
  };
  return (
    <>
      <div className="flex flex-col w-40">
        <Menu as="div" className="relative inline-block z-50">
          {({ open }) => (
            <>
              <Menu.Button className="inline-flex items-center">
                <span className="font-bold text-lg">{title}</span>
                <ChevronDownIcon
                  className="w-5 h-5 ml-2 text-lg -mr-1 text-violet-200 hover:text-violet-100"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                show={open}
                enter="transition duration-100 ease-out z-50"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Menu.Items
                  static
                  as="div"
                  className="absolute z-50 right-0 w-32 mt-2 origin-top-right bg-white shadow-md rounded-md p-2 ring-1 ring-black ring-opacity-5 text-base"
                >
                  {rangePrice && (
                    <div className="text-sm grid grid-rows-2 gap-2">
                      <PriceInput
                        price={rangePrice[0]}
                        setPrice={setMinPrice}
                        minmax="min"
                      />
                      <PriceInput
                        price={rangePrice[1]}
                        setPrice={setMaxPrice}
                        minmax="max"
                      />
                    </div>
                  )}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
        <div className="flex items-center text-sm">
          {(rangePrice[0] > 0 || rangePrice[1] < 999) && (
            <div className="p-0.5 mt-2 px-2 bg-gray-100 text-gray-600 rounded-full inline-block">
              {rangePrice[0] > 0 && rangePrice[1] < 999 && (
                <span>
                  {rangePrice[0]} - {rangePrice[1]}€
                </span>
              )}
              {rangePrice[0] > 0 && rangePrice[1] === 999 && (
                <span>ab {rangePrice[0]}€</span>
              )}
              {rangePrice[0] === 0 && rangePrice[1] < 999 && (
                <span>bis {rangePrice[1]}€</span>
              )}
              <IconButton
                onClick={() => setFilterPrice([0, 999])}
                size="xs"
                marginLeft={"0.5rem"}
                isRound
                colorScheme="gray"
                icon={<SmallCloseIcon />}
                aria-label="reset person filter"
              />
            </div>
          )}
          {rangePrice[0] === 0 && rangePrice[1] === 999 && (
            <div className="flex items-center">
              <div className="mt-2 px-2 py-1.5 h-7 flex items-center bg-gray-100 text-gray-600 rounded-full inline-block">
                Alle
              </div>
            </div>
          )}
          {rangePrice[0] > 0 && rangePrice[1] < 999 && (
            <div className="p-0.5 mt-2 px-2 bg-gray-100 text-gray-600 rounded-full inline-block">
              {rangePrice[0]} - {rangePrice[1]}€
              <IconButton
                onClick={() => setFilterPrice([0, 999])}
                size="xs"
                marginLeft={"0.5rem"}
                isRound
                colorScheme="gray"
                icon={<SmallCloseIcon />}
                aria-label="reset price filter"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const SimpleFilterMenu = ({ items, name, filter, title }) => {
  items = items[name];
  const filterValues = filter["simpleFilter"][name].values;
  const setFilter = filter["simpleFilter"][name].set;
  return (
    <>
      <div className="flex flex-col">
        <Menu as="div" className="relative inline-block z-50">
          {({ open }) => (
            <>
              <Menu.Button className="inline-flex items-center">
                <span className="font-bold text-lg">{title}</span>
                <ChevronDownIcon
                  className="w-5 h-5 ml-2 text-lg -mr-1 text-violet-200 hover:text-violet-100"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                show={open}
                enter="transition duration-100 ease-out z-50"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Menu.Items
                  static
                  as="div"
                  className="absolute z-50 right-0 w-32 mt-2 origin-top-right bg-white shadow-md rounded-md p-2 ring-1 ring-black ring-opacity-5 text-base"
                >
                  {items &&
                    items.map((p, i) => (
                      <Menu.Item key={i} as={"div"}>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              if (filterValues.indexOf(p) < 0)
                                filterValues.push(p);
                              setFilter([...filterValues]);
                            }}
                            className={`w-full py-1.5 px-0.5 rounded-md ${
                              active && "bg-1 bg-opacity-20"
                            }`}
                          >
                            {p}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
        {items && (
          <div className="w-40 text-sm">
            {items.filter((item) => filterValues.indexOf(item) >= 0) &&
              items
                .filter((item) => filterValues.indexOf(item) >= 0)
                .map((item, i) => (
                  <div key={i} className="flex items-center">
                    <div className="p-0.5 mt-2 px-2 bg-gray-100 text-gray-600 rounded-full inline-block">
                      {item}
                      <IconButton
                        onClick={() =>
                          setFilter(filterValues.filter((i) => i !== item))
                        }
                        size="xs"
                        marginLeft={"0.5rem"}
                        isRound
                        colorScheme="gray"
                        icon={<SmallCloseIcon />}
                        aria-label="reset person filter"
                      />
                    </div>
                  </div>
                ))}
            {items.filter(
              (item) => filterValues.filter((i) => i === item).length
            ).length < 1 && (
              <div className="flex items-center">
                <div className="mt-2 px-2 py-1.5 h-7 flex items-center bg-gray-100 text-gray-600 rounded-full inline-block">
                  Alle
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default FilterSection;

import React from "react";
import Card from "../BuildingBlocks/Card";

export default function Idea({ data }) {
  const image = data.imageCollection.items[0].url;
  const title = data.title;
  const type = "idee";
  return (
    <div className="w-full">
      <Card title={title} imageUrl={image} type={type} noTitle={true}>
        <div className="flex flex-wrap md:flex-nowrap justify-between items-start mb-4">
          <h2 className="text-sm sm:text-base py-2">{title}</h2>
          <h2 className="text-sm float-right py-0.5 mt-2.5 rounded-full bg-gray-100 inline-block text-center px-2">
            {data.price ? data.price : "0"}€
          </h2>
        </div>
      </Card>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Idea from "./Idea";

import "rc-slider/assets/index.css";
import SortingBar from "./SortingBar";
import FilterSection from "./FilterSection";
import MoreContent from "../BuildingBlocks/MoreContent";
import { Button } from "@chakra-ui/react";

const QUERY = (lowerPrice, upperPrice) => `{
  ideeCollection(limit: 500, where:{AND:[{price_gte : ${lowerPrice}}, {price_lte : ${upperPrice}}]}) {
    items {
      title
      imageCollection(limit: 1) {
        items {
          url(transform: {
            height: 700})
        }
      }
      personCollection(limit: 5) {
        items {
          title
        }
      }
      eventCollection(limit: 5) {
        items {
          title
        }
      }
      price
    }
  }
  personCollection {
    items {
      title
    }
  }
  anlassCollection {
    items {
      title
    }
  }
}`;

const IdeaList = ({ event, person, title, displayFilter }) => {
  const [dataitems, setData] = useState(null);

  {
    /* Simple Filters */
  }
  const [filterPerson, setFilterPerson] = useState(person ? [person] : []);
  const [filterEvent, setFilterEvent] = useState(event ? [event] : []);
  const [filterItems, setFilterItems] = useState({ person: [], event: [] });
  {
    /* Range Filters */
  }
  const [filterPrice, setFilterPrice] = useState([0, 999]);

  const filter = {
    simpleFilter: {
      person: { values: filterPerson, set: setFilterPerson },
      event: { values: filterEvent, set: setFilterEvent },
    },
    rangeFilter: {
      price: { values: filterPrice, set: setFilterPrice },
    },
  };

  const [sortingOption, setSorting] = useState({ sorting: () => 1 });
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/rkj7wul4lgic/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer bsL0XMuEFiswVO4MWlIHv-jsQjtn-u_7G4lnvnBe0Is",
        },
        body: JSON.stringify({
          query: QUERY(filterPrice[0], filterPrice[1]),
        }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setData(data?.ideeCollection?.items);
        setFilterItems({
          event: data?.anlassCollection?.items.map((i) => i.title),
          person: data?.personCollection?.items.map((i) => i.title),
        });
      });
  }, [filterPrice]);

  const eventText = event
    ? event.endsWith("tag")
      ? ` zum ${event}`
      : ` für ${event}`
    : "";

  if (!dataitems) {
    return <div className="container-xl min-h-screen" />;
  }

  let ideas = dataitems;

  // filter items for person and event
  ideas = filterPerson.length
    ? ideas.filter((item) => {
        if (item.personCollection.items.length > 0)
          return (
            item.personCollection.items
              .map((itemPerson) => filterPerson.indexOf(itemPerson.title) >= 0)
              .reduce((a, b) => a + b) > 0
          );
        return 0;
      })
    : ideas;

  ideas = filterEvent.length
    ? ideas.filter(
        (item) =>
          item.eventCollection.items
            .map((itemEvent) => filterEvent.indexOf(itemEvent.title) >= 0)
            .reduce((a, b) => a + b) > 0
      )
    : ideas;

  // sort items
  ideas = ideas.sort(sortingOption.sorting);

  // slice data
  const numAllItems = ideas.length;
  // items = items.slice(0, numItems);
  const w = window.innerWidth;
  const itemsPerPage =
    w < 640 ? 20 : w < 768 ? 21 : w < 1280 ? 20 : w < 1536 ? 24 : 28;

  return (
    <div className="section my-12">
      {title !== false && (
        <div className="pb-8">Inspirierende Ideen{eventText}!</div>
      )}
      <div className="flex" id="idealist">
        <div className="w-full flex flex-wrap">
          {displayFilter && (
            <FilterSection filterItems={filterItems} filter={filter} />
          )}
          {displayFilter && <SortingBar setSorting={setSorting} />}
          <div className="w-full flex flex-wrap mb-20 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-12">
            {ideas
              .filter(
                (item, i) =>
                  pageIndex * itemsPerPage <= i &&
                  i < (pageIndex + 1) * itemsPerPage
              )
              .map((item, i) => (
                <Idea data={item} key={i} />
              ))}
          </div>

          {displayFilter === true && (
            <div className="w-full flex justify-center text-lg items-center">
              <div className="relative">
                <span className="-ml-2 absolute left-3 top-1/2 transform -translate-y-1/2 -translate-x-1">
                  Seite
                </span>
              </div>
              {[...Array(Math.ceil(numAllItems / itemsPerPage)).keys()]
                .filter(
                  (it, i, a) =>
                    i === 0 ||
                    i === a.length - 1 ||
                    i === pageIndex ||
                    i === pageIndex - 1 ||
                    i === pageIndex + 1
                )
                .map((n) => (
                  <PageNumberButton
                    n={n}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                  />
                ))}
            </div>
          )}

          {displayFilter !== true && (
            <MoreContent
              text={"Alle Ideen"}
              path="geschenkideen"
              state={{ event, person }}
              visible={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const PageNumberButton = ({ n, pageIndex, setPageIndex }) => (
  <Button
    key={n}
    colorScheme={n === pageIndex ? "red" : "gray"}
    title={n + 1}
    fontWeight={"normal"}
    rounded={"9999px"}
    size="sm"
    aria-label={n + 1}
    marginX={"0.2rem"}
    onClick={() => {
      document.querySelector("#idealist").scrollIntoView();
      setPageIndex(n);
    }}
  >
    {n + 1}
  </Button>
);

export default IdeaList;

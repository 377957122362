import { Select } from "@chakra-ui/react";
import React from "react";

export default function SortingBar(props) {
  const initial = { name: "Option wählen", sorting: () => 1 };

  const options = [
    { name: "Alphabetisch aufsteigend", sorting: sortAlphabetically },
    { name: "Alphabetisch absteigend", sorting: sortAlphabeticallyInverse },
    { name: "Preis aufsteigend", sorting: sortPrice },
    { name: "Preis absteigend", sorting: sortPriceInverse },
  ];

  const handleChange = (event) => {
    const index = event.target.value;
    props.setSorting(index ? options[index] : initial);
  };

  return (
    <div className="md:ml-auto py-4 flex flex-col justify-end items-start">
      <div className="text-lg mt-1.5 pt-0.5 font-bold font-sans">Sortieren</div>
      <div className="w-auto">
        <Select placeholder={initial.name} size="sm" onChange={handleChange}>
          {options.map((option, i) => (
            <option key={i} value={i}>
              {option.name}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export const sortAlphabetically = (a, b) => {
  a = /[a-zA-Z]/.test(a?.title[0]) ? a?.title[0] : a?.title[1];
  b = /[a-zA-Z]/.test(b?.title[0]) ? b?.title[0] : b?.title[1];
  return a?.localeCompare(b);
};

const sortAlphabeticallyInverse = (a, b) => {
  a = /[a-zA-Z]/.test(a?.title[0]) ? a?.title[0] : a?.title[1];
  b = /[a-zA-Z]/.test(b?.title[0]) ? b?.title[0] : b?.title[1];
  return -1;
};

const sortPrice = (a, b) => {
  return a.price - b.price;
};

const sortPriceInverse = (a, b) => {
  return b.price - a.price;
};
